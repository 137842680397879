import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export function createAxiosInstance(config?: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(config);

  // Axios errors contain a lot of context information and produce thousands of lines when logged
  // causing out of memory crashes. "Wrapping" them to mitigate that.
  instance.interceptors.request.use(undefined, (err) => {
    if (err.response?.data?.error) {
      return Promise.reject(
        new Error(`Axios response error; Reason: "${JSON.stringify(err.response.data.error)}"`)
      );
    }
    return Promise.reject(new Error("Axios request error; Caused by: " + err.message));
  });

  instance.interceptors.response.use(undefined, (err) => {
    if (err.response?.data?.error) {
      return Promise.reject(
        new Error(`Axios response error; Reason: "${JSON.stringify(err.response.data.error)}"`)
      );
    }
    return Promise.reject(new Error("Axios response error; Caused by: " + err.message));
  });

  return instance;
}
