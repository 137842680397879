import { z } from "zod";

export const SourceTypeSchema = z.enum([
  "all",
  "apify",
  "app",
  "csv",
  "dixa",
  "free-form-text",
  "freshdesk",
  "front",
  "group",
  "intercom",
  "jira",
  "kustomer",
  "survey",
  "twitter",
  "zapier",
  "zendesk",
  "fivetran",
]);

export const SourceStatusSchema = z.enum(["creating", "initialSync", "active"]);

export const SourceContentTypeSchema = z.enum([
  "COMMENT",
  "CSV",
  "FREE_FORM_TEXT",
  "REVIEW",
  "SURVEY",
  "TICKET",
  "UNKNOWN",
  "TRANSCRIPT",
]);

export const SourceOriginServiceSchema = z.enum([
  "AMAZON",
  "ANDROID",
  "CANNY",
  "CLICKUP",
  "CRISP",
  "DELIGHTED",
  "DISCORD",
  "DIXA",
  "DOVETAIL",
  "EXCEL",
  "FATHOM",
  "FIREFLIES",
  "FRESHDESK",
  "FRONT",
  "G2",
  "GONG",
  "GOOGLESHEET",
  "GOOGLE_MEET",
  "HELP_SCOUT",
  "HOTJAR",
  "HUBSPOT",
  "INTERCOM",
  "IOS",
  "JIRA",
  "KRAFTFUL",
  "KUSTOMER",
  "NOTION",
  "REDDIT",
  "SALESFORCE",
  "SALESFORCE_SERVICE",
  "SLACK",
  "SURVEYMONKEY",
  "TRUSTPILOT",
  "TWITTER",
  "TYPEFORM",
  "UNKNOWN",
  "ZENDESK",
  "ZOOM",
]);

export const SourceIntegrationServiceSchema = z.enum([
  "APIFY",
  "MERGE",
  "SERP",
  "ZAPIER",
  "FIVETRAN",
]);

export const SourceCountSchema = z.object({
  feedbackAnalysisItems: z.number(),
  feedbackThreads: z.number(),
  feedbackComments: z.number(),
  surveyResponses: z.number(),
});

export const BaseSourceExtraSchema = z.object({
  excludedAuthors: z.array(z.string()).optional(),
  appendFileMetadata: z
    .array(
      z.object({
        date: z.string().min(1),
        rowCount: z.number().nonnegative(),
        fileName: z.string().min(1),
      })
    )
    .optional(),
});

export type SourceType = z.infer<typeof SourceTypeSchema>;
export type SourceStatus = z.infer<typeof SourceStatusSchema>;
export type SourceContentType = z.infer<typeof SourceContentTypeSchema>;
export type SourceOriginServiceType = z.infer<typeof SourceOriginServiceSchema>;
export type SourceIntegrationServiceType = z.infer<typeof SourceIntegrationServiceSchema>;

export function toIntegrationServiceTypeOrNull(val: unknown): SourceIntegrationServiceType | null {
  const parseResult = SourceIntegrationServiceSchema.safeParse(val);
  if (parseResult.success) {
    return parseResult.data;
  }

  return null;
}
