export function toEnum<T extends readonly string[], K extends T[number] = T[number]>(
  values: T
): { [key in K]: key } {
  return values.reduce((c, n) => {
    c[n] = n;
    return c;
  }, {} as { [key in typeof values[number]]: key });
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;
